<template>
  <div class="md:mt-4 p-5">
    <t-alert variant="success" class="my-2" :timeout="5000" :show="showSuccess">
      {{ $t("scenario_success") }}
    </t-alert>
    <div class="mb-10">
      <form class="flex justify-between items-center" @submit="onSaveScenario">
        <div>
          <label class="font-semibold">{{ $t("scenario_name") }}</label>
          <t-input class="height-fix mt-2" required v-model="scenarioName" />
        </div>
        <div>
          <div class="flex gap-5">
            <t-button
              type="button"
              class="text-sm mt-8"
              variant="primary"
              :disabled="!scenarioId || loading"
              @click="toggleCopyModal = true"
            >
              <i class="fas fa-clone"></i>
              {{ $t("scenario_copy") }}
            </t-button>
            <t-button
              type="submit"
              class="text-sm mt-8"
              variant="success"
              :disabled="loading"
            >
              <i class="fas fa-save"></i>
              {{ $t("scenario_save") }}
            </t-button>
          </div>
        </div>
      </form>
    </div>
    <table
      class="
        text-sm
        w-full
        divide-y divide-gray-100
        shadow-sm
        border-gray-200 border
        my-3
      "
    >
      <thead>
        <tr>
          <th
            v-for="(header, key) in headers"
            class="
              p-2
              font-semibold
              text-base text-left
              bg-gray-200
              border-b
              week-header
            "
            :key="`${key}-header`"
          >
            {{ header }}
          </th>
        </tr>
        <tr>
          <th
            v-for="index in headers"
            class="border-b d-flex py-2"
            :key="`${index}-columns`"
          >
            <div class="flex-container">
              <div class="pt-3 pl-3 font-semibold flex-shift">
                {{ $t("scenario_shift") }}
              </div>
              <div class="pt-3 font-semibold flex-max">
                {{ $t("scenario_shift_max") }}
              </div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody class="bg-white divide-t divide-gray-100">
        <tr class="mb-5 pb-5">
          <td
            v-for="(days, index) in scenarioShifts"
            :key="index"
            class="px-3 pt-1 align-top"
          >
            <div
              v-for="(data, ddi) in days"
              :key="`${ddi}${index}-cell`"
              class="shift-row"
            >
              <div class="flex-container">
                <div
                  class="shift flex-container p-1 border-b"
                  @click="showShiftModal(data.day, data)"
                >
                  <div class="py-2 flex-item1">
                    {{ scenarioShiftsOptions(data.from, data.until) }}
                  </div>
                  <div class="py-2 flex-item2">
                    {{ data.max_employees }}
                  </div>
                </div>
                <t-button
                  variant="danger"
                  class="remove-button flex-item3 text-xs mt-2"
                  :disabled="loading"
                  @click="deleteScenarioShift(data)"
                >
                  <i class="fas fa-times text-white"></i>
                </t-button>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td v-for="day in 7" :key="day" class="px-1 py-2">
            <t-button
              @click="showShiftModal(day)"
              class="bg-orange-400 hover:bg-orange-500 mt-3 mx-auto"
              variant="primary"
              :disabled="loading"
            >
              <i class="fas fa-plus text-xs items-center"></i>
              {{ $t("scenario_shift_add") }}
            </t-button>
          </td>
        </tr>
      </tbody>
    </table>
    <!-- Shift Modal -->
    <t-modal v-model="toggleShiftModal">
      <t-alert class="my-2" variant="danger" :timeout="4000" :show="showError">
        {{ $t("scenario_shift_exists") }}
      </t-alert>
      <form
        class="flex flex-col px-2 pt-4"
        @submit="onCreateShift"
        ref="shiftForm"
      >
        <div class="flex flex-col mx-auto w-48">
          <label class="font-semibold mb-3">{{ $t("edit_timeslot") }}</label>
          <div class="flex items-center gap-4">
            <input
              type="radio"
              class="cursor-pointer"
              id="heledag"
              name="hour"
              @click="formHour = 1"
              :checked="formHour === 1"
              required
            />
            <label class="cursor-pointer" for="heledag">
              {{ $t("available_select_hour_modal_allday_option") }}
            </label>
          </div>
          <div class="flex items-center gap-4">
            <input
              type="radio"
              class="cursor-pointer"
              id="avond"
              name="hour"
              @click="formHour = 2"
              :checked="formHour === 2"
              required
            />
            <label class="cursor-pointer" for="avond">
              {{ $t("available_select_hour_modal_evening_option") }}
            </label>
          </div>
          <div class="flex items-center gap-4">
            <input
              type="radio"
              class="cursor-pointer"
              id="anders"
              name="hour"
              @click="formHour = 3"
              :checked="formHour === 3"
              required
            />
            <label class="cursor-pointer" for="anders">
              {{ $t("available_select_hour_modal_custom_option") }}
            </label>
          </div>
          <div
            v-if="formHour === 3"
            class="flex flex-col items-center gap-2 mt-2"
          >
            <t-select
              class="w-full"
              v-model="form.from"
              :options="timeslots.start"
              :variant="!isValidCustomDate ? 'danger' : ''"
              required
            ></t-select>
            <t-select
              class="w-full"
              v-model="form.until"
              :options="timeslots.end"
              :variant="!isValidCustomDate ? 'danger' : ''"
              required
            ></t-select>
          </div>
          <div class="mt-5">
            <label class="font-semibold mb-2">{{ $t("max_employees") }}</label>
            <t-input
              class="my-2 w-full"
              type="number"
              min="1"
              max="5000"
              v-model="form.max_employees"
              required
            />
          </div>
        </div>
      </form>
      <template v-slot:footer>
        <div class="flex justify-end gap-4">
          <t-button
            type="submit"
            class="text-sm"
            variant="primary"
            :disabled="loading"
            @click="submitShiftForm"
            >{{ $t("save") }}</t-button
          >
          <t-button
            type="button"
            class="text-sm"
            :disabled="loading"
            @click="toggleShiftModal = false"
            >{{ $t("close") }}</t-button
          >
        </div>
      </template>
    </t-modal>
    <!-- ./Shift Modal -->

    <!-- Copy Modal -->
    <t-modal v-model="toggleCopyModal">
      <div class="mb-3 px-2">
        <label>{{ $t("scenario_name") }}</label>
        <t-input
          class="my-2 w-full"
          :variant="errors.copyName ? 'danger' : ''"
          v-model="copyForm.name"
        />
      </div>
      <template v-slot:footer>
        <div class="flex justify-end gap-4">
          <t-button
            class="text-sm"
            variant="primary"
            :disabled="loading"
            @click="copyScenario"
            >{{ $t("save") }}</t-button
          >
          <t-button
            type="button"
            class="text-sm"
            :disabled="loading"
            @click="toggleCopyModal = false"
            >{{ $t("close") }}</t-button
          >
        </div>
      </template>
    </t-modal>
    <t-dialog icon="question" type="confirm"></t-dialog>
    <!-- ./Copy Modal -->
  </div>
</template>

<script>
import { actions, getters } from "@/constants/state";
import { copy } from "@/services/scenario-service";
import {
  getEndTimeDay,
  getScenarioShiftTimes,
  getTimeSlotOptions,
} from "@/utils/misc";
import { clone, find, get, lowerCase, pull } from "lodash";
import moment from "@/utils/moment-utc";
import { mapActions, mapGetters } from "vuex";

export default {
  data: () => ({
    loading: false,
    saveLoading: false,
    toggleShiftModal: false,
    toggleCopyModal: false,
    showError: false,
    showSuccess: false,
    scenarioId: null,
    scenarioName: null,
    deletedShifts: [],
    selectedShift: {},
    formHour: 0,
    scenarioShifts: {},
    form: {
      id: null,
      scenario_id: null,
      day: 0,
      from: null,
      until: null,
      max_employees: 0,
    },
    copyForm: {
      name: null,
    },
    hourType: {
      allday: 1,
      evening: 2,
      custom: 3,
    },
    errors: {
      copyName: false,
    },
  }),

  mounted() {
    this.setScenarioWeek();

    if (this.$route.params.id) {
      this.scenarioId = this.$route.params.id;
      this.scenarioName = get(this.scenario, "name");

      this.setScenarioShifts();
    }
  },

  methods: {
    ...mapActions({
      createScenario: actions.SCENARIOS_CREATE_ACTION,
      updateScenario: actions.SCENARIOS_UPDATE_ACTION,
    }),

    setScenarioWeek() {
      this.scenarioShifts = {
        monday: [],
        tuesday: [],
        wednesday: [],
        thursday: [],
        friday: [],
        saturday: [],
        sunday: [],
      };
    },

    setScenarioShifts() {
      const shifts = get(this.scenario, "scenario_shifts");

      if (shifts) {
        for (let shift of shifts) {
          const dayName = lowerCase(moment().day(shift.day).format("dddd"));

          this.scenarioShifts[dayName].push(shift);
        }
      }

      this.sortScenarioShifts();
    },

    async showShiftModal(day, data) {
      this.form = {
        id: get(data, "id", null),
        scenario_id: get(data, "scenario_id", null),
        from: get(data, "from", "07:00"),
        until: get(data, "until", "02:00"),
        day: get(data, "day", day),
        max_employees: get(data, "max_employees", null),
      };

      if (get(data, "from") && get(data, "until")) {
        if (data.from === "07:00" && data.until === "02:00") {
          this.formHour = this.hourType.allday;
        } else if (data.from === "17:00" && data.until === "02:00") {
          this.formHour = this.hourType.evening;
        } else {
          this.formHour = this.hourType.custom;
        }
      } else {
        this.formHour = 0;
      }

      this.selectedShift = data;
      this.showError = false;
      this.toggleShiftModal = true;
    },

    submitShiftForm() {
      this.$refs.shiftForm.requestSubmit();
    },

    async onCreateShift(e) {
      e.preventDefault();
      this.loading = true;

      if (this.formHour === this.hourType.allday) {
        this.form.from = "07:00";
        this.form.until = "02:00";
      }

      if (this.formHour === this.hourType.evening) {
        this.form.from = "17:00";
        this.form.until = "02:00";
      }

      const values = {
        from: this.form.from,
        until: this.form.until,
        day: this.form.day,
        max_employees: this.form.max_employees,
      };

      const dayName = lowerCase(moment().day(this.form.day).format("dddd"));

      if (this.selectedShift) {
        if (this.shiftValidator(dayName)) {
          return;
        }

        this.scenarioShifts[dayName] = pull(
          clone(this.scenarioShifts[dayName]),
          this.selectedShift
        );

        let editedShift = {
          from: this.form.from,
          until: this.form.until,
          day: this.form.day,
          max_employees: this.form.max_employees,
        };

        get(this.form, "id") ? (editedShift["id"] = this.form.id) : {};
        get(this.form, "scenario_id")
          ? (editedShift["scenario_id"] = this.form.scenario_id)
          : {};

        this.scenarioShifts[dayName].push(editedShift);
      } else {
        if (this.shiftValidator(dayName)) {
          return;
        }

        this.scenarioShifts[dayName].push(values);
      }

      this.sortScenarioShifts();

      this.loading = false;
      this.toggleShiftModal = false;
    },

    async onSaveScenario(e) {
      e.preventDefault();

      const payload = {
        name: this.scenarioName,
        shifts: this.scenarioShifts,
        deleted: this.deletedShifts,
      };

      this.showSuccess = false;
      this.loading = true;
      this.saveLoading = true;

      if (this.$route.params.id) {
        try {
          await this.updateScenario({
            id: this.$route.params.id,
            payload,
          });

          this.showSuccess = true;
        } catch (error) {
          console.error("DEBUG: update scenario", error);
        }
      } else {
        try {
          const scenario = await this.createScenario(payload);

          if (scenario) {
            this.$router.push(
              `/manager/scenarios/scenario-shifts/${get(scenario, "id")}`
            );
            this.scenarioId = get(scenario, "id");
          }

          this.showSuccess = true;
        } catch (error) {
          console.error("DEBUG: create scenario", error);
        }
      }

      this.setScenarioWeek();
      this.setScenarioShifts();

      this.loading = false;
      this.saveLoading = false;
    },

    async deleteScenarioShift(shift) {
      const dayName = lowerCase(moment().day(shift.day).format("dddd"));

      if (shift.id) {
        this.deletedShifts.push(shift.id);
      }

      this.scenarioShifts[dayName] = pull(
        clone(this.scenarioShifts[dayName]),
        shift
      );
    },

    shiftValidator(dayName) {
      const sameShift = find(this.scenarioShifts[dayName], {
        day: this.form.day,
        from: this.form.from,
        until: this.form.until,
      });

      if (this.form.from === this.form.until) {
        this.loading = false;

        return true;
      }

      if (!this.selectedShift && sameShift) {
        this.showError = true;
        this.loading = false;

        return true;
      }

      if (this.selectedShift) {
        if (
          this.selectedShift.from === this.form.from &&
          this.selectedShift.until === this.form.until
        ) {
          return false;
        }

        if (
          sameShift &&
          sameShift.from === this.form.from &&
          sameShift.until === this.form.until
        ) {
          this.showError = true;
          this.loading = false;

          return true;
        }
      }
    },

    async copyScenario() {
      this.errors["copyName"] = !get(this.copyForm, "name");

      if (Object.values(this.errors).some((error) => error)) {
        return;
      }

      try {
        const payload = {
          scenarioId: this.scenarioId,
          name: get(this.copyForm, "name"),
        };

        await copy(payload);

        return this.$router.push({
          path: "/manager/scenarios",
        });
      } catch (error) {
        console.error("DEBUG: create scenario", error);
      }
    },

    scenarioShiftsOptions(from, until) {
      return getScenarioShiftTimes(from, until);
    },

    sortScenarioShifts() {
      let sortedShifts = {};

      for (const key in this.scenarioShifts) {
        sortedShifts[key] = this.scenarioShifts[key].sort(function (a, b) {
          return a.from.localeCompare(b.from);
        });
      }
    },
  },

  computed: {
    ...mapGetters({
      scenario: getters.SCENARIOS_SCENARIO_GETTER,
    }),

    headers() {
      return [
        this.$i18n.t("monday"),
        this.$i18n.t("tuesday"),
        this.$i18n.t("wednesday"),
        this.$i18n.t("thursday"),
        this.$i18n.t("friday"),
        this.$i18n.t("saturday"),
        this.$i18n.t("sunday"),
      ];
    },

    timeslots() {
      return getTimeSlotOptions();
    },

    isValidCustomDate() {
      const currentDate = moment().format("YYYY-MM-DD");

      return (
        this.formHour === 3 &&
        moment(`${currentDate} ${this.form.from}`).isBefore(
          getEndTimeDay(currentDate, this.form.from, this.form.until)
        )
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.shift:hover {
  background-color: rgba(135, 149, 135, 0.16);
  cursor: pointer;
}

.shift {
  width: 80%;
}

.flex-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 95%;
}

.flex-item1 {
  width: 115px;
  height: 30px;
}

.flex-item2 {
  width: 30px;
  height: 30px;
  text-align: left;
  margin-right: 5%;
}

.flex-item3 {
  width: 10px;
  height: 25px;
  text-align: center;
  padding-top: 4px;
  padding-left: 8px;
  margin-left: 5px;
}

.flex-shift {
  width: 120px;
  height: 30px;
  text-align: left;
}

.flex-max {
  width: 30px;
  height: 30px;
  margin-right: 24%;
  text-align: left;
}

.shift-row:nth-child(odd) {
  background-color: rgba(243, 244, 246, 0.7);
}
</style>
